import "./Footer.css";
import callIcon from "../../assets/images/icon/call.svg";
import mapIcon from "../../assets/images/icon/map.svg";
import emailIcon from "../../assets/images/icon/email.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="planocast-footer-section dark-bg">
      <div className="container">
        <div className="planocast-footer-extra-top">
          <div className="row">
            <div className="col-lg-7">
              <div className="planocast-footer-extra-title">
                <h2>Elevate your events with PlanOCast</h2>
              </div>
            </div>
            <div className="col-lg-5 d-flex align-items-center">
              <div className="planocast-footer-btn">
                <a
                  className="planocast-default-btn pill"
                  href="https://vccground.planocast.com"
                >
                  <span>Get started now</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="planocast-footer-top">
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              <div className="planocast-footer-textarea light">
                <a
                  className="navbar-brand"
                  style={{ color: "var(--primary-color)" }}
                  href="/"
                >
                  planocast.
                </a>
                <p>
                  PlanOCast is your go-to platform for seamless event planning.
                  Join us to transform your event ideas into unforgettable
                  experiences.
                </p>
                {/* <div className="planocast-social-icon social-box social-box-white">
                  <ul>
                    <li>
                      <a
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://facebook.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://github.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-github"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              {/* Uncomment and fill if needed
              <div className="planocast-footer-menu light extar-margin">
                <div className="planocast-footer-title light">
                  <p>Navigation</p>
                </div>
                <ul>
                  <li><a href="">About Us</a></li>
                  <li><a href="">Services</a></li>
                  <li><a href="">Contact</a></li>
                </ul>
              </div>
              */}
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="planocast-footer-menu light">
                <div className="planocast-footer-title light">
                  <p>Useful Links</p>
                </div>
                <ul>
                  <li>
                    <Link to="/terms-conditions">Terms And Conditions</Link>
                  </li>
                  <li>
                    <Link to="/cancellation-refund-policy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="planocast-footer-menu light info extar-margin">
                <div className="planocast-footer-title light">
                  <p>Contact us</p>
                </div>
                <ul>
                  <li>
                    <a href="tel:+916370516483">
                      <img src={callIcon} alt="Call Icon" /> +91 6370 516 483
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@planocast.com">
                      <img src={emailIcon} alt="Email Icon" />{" "}
                      info@planocast.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maps.google.com/?q=Sector+21+Gurugram+Delhi+122016"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={mapIcon} alt="Map Icon" /> Gurugram, Delhi
                      122016
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="planocast-footer-bottom center light">
          <div className="planocast-copywright light">
            <p>&copy; 2024, All Rights Reserved by PlanOCast</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { Footer } from "./components/commons/Footer";
import Navbar from "./components/commons/Navbar";
import { ContactUs } from "./components/contactUs/ContactUs";
import { FAQs } from "./components/faqs/FAQs";
import { Features } from "./components/features/Features";
import Header from "./components/header/Header";
import "./assets/css/main.css";
import "./assets/css/app.min.css";
import { Pricing } from "./components/pricing/Pricing";

function App() {
  return (
    <>
      <Navbar />
      <Header />
      <Features />
      <Pricing />
      <FAQs />
      <ContactUs />
      <Footer />
    </>
  );
}

export default App;

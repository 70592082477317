import React from "react";

export const Pricing = () => {
  const pricingPlans = [
    {
      id: "1",
      title: "Event Management",
      price: "749",
      currency: "₹",
      originalPrice: "1499",
      perks: [
        "Event creation and updates",
        "Autogenerated PDF download",
        "Event timeline view",
      ],
    },
    {
      id: "2",
      title: "Customer Management",
      price: "499",
      currency: "₹",
      originalPrice: "999",
      perks: ["Customer engagement tools", "Event updates", "Notifications"],
    },
    {
      id: "3",
      title: "Vendor Management",
      price: "999",
      currency: "₹",
      originalPrice: "1999",
      perks: ["Vendor onboarding", "Task assignment", "Communication tools"],
    },
    {
      id: "4",
      title: "Guest Management",
      price: "499",
      currency: "₹",
      originalPrice: "999",
      perks: [
        "Digital invitations",
        "RSVP management",
        "Reminder notifications",
        "Guest insights",
      ],
    },
    {
      id: "5",
      title: "Email Management",
      price: "449",
      currency: "₹",
      originalPrice: "899",
      perks: ["Email template creation", "Bulk email sending"],
    },
    {
      id: "6",
      title: "Dashboard and Budget Management",
      price: "749",
      currency: "₹",
      originalPrice: "1499",
      perks: ["Budget insights", "Customizable dashboards"],
    },
    {
      id: "7",
      title: "Event Feed",
      price: "849",
      currency: "₹",
      originalPrice: "1699",
      perks: ["Photo collection", "Event memory tracking"],
    },
    {
      id: "8",
      title: "Feedback Management",
      price: "349",
      currency: "₹",
      originalPrice: "699",
      perks: ["Feedback collection", "Analysis tools"],
    },
    {
      id: "9",
      title: "Complete Application for Wedding",
      price: "1499",
      currency: "₹",
      originalPrice: "2999",
      perks: ["All features included"],
    },
    {
      id: "10",
      title: "Complete Application for Corporate",
      price: "2499",
      currency: "₹",
      originalPrice: "4999",
      perks: ["All features included"],
    },
    {
      id: "11",
      title: "Complete Application for Social/Personal Events",
      price: "499",
      currency: "₹",
      originalPrice: "999",
      perks: ["All features included"],
    },
  ];

  const calculateDiscountPercentage = (originalPrice, price) => {
    return Math.round(((originalPrice - price) / originalPrice) * 100);
  };

  return (
    <>
      <div className="header-section text-center">
        <h3 className="font-weight-bold">PlanOCast Pricing</h3>
        <br />
        <small>
          Discover the comprehensive and affordable pricing plans of PlanOCast,
          India's leading event planning platform. Designed to cater to all your
          event management needs, PlanOCast offers a range of features to ensure
          your events are a resounding success. Whether you're managing customer
          relations, vendor collaborations, or guest invitations, our platform
          provides scalable solutions at competitive prices.
        </small>
      </div>
      <div className="pricing-container container text-center">
        <div className="row">
          {pricingPlans.map((plan) => (
            <div
              className="col-lg-4 col-md-6 col-sm-10 pb-4 m-auto"
              key={plan.id}
            >
              <div className="pricing-item">
                <div className="pt-4 pb-3">
                  <h4>{plan.title}</h4>
                </div>
                <div className="pricing-price pb-1 color-primary-text">
                  <div>
                    <span className="original-price">
                      {plan.currency}
                      {plan.originalPrice}
                    </span>
                    <span className="discount">
                      {calculateDiscountPercentage(
                        plan.originalPrice,
                        plan.price
                      )}
                      % OFF
                    </span>
                  </div>
                  <h1 className="live-price">
                    <span>{plan.currency}</span>
                    {plan.price}
                  </h1>
                </div>
                <div className="pricing-description">
                  <ul className="list-unstyled mt-3 mb-4">
                    {plan.perks.map((perk, index) => (
                      <li key={index}>{perk}</li>
                    ))}
                  </ul>
                </div>
                <div className="pricing-button pb-4">
                  <button type="button" className="btn btn-lg btn-primary w-75">
                    Get started
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

// CSS styling for the pricing component
const styles = `
  .header-section {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px 0;
  }
  .header-section h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  .header-section p {
    font-size: 1.2em;
    margin-bottom: 40px;
  }
  .pricing-container {
    max-width: 80%;
    margin: 0 auto;
  }
  .pricing-item {
    box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.2);
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .pricing-item h4 {
    letter-spacing: 1px;
    font-size: 1.5em;
  }
  .original-price {
    text-decoration: line-through;
    color: red;
    margin-right: 10px;
    font-size: 1em;
  }
  .discount {
    color: green;
    font-size: 1em;
  }
  .live-price {
    font-weight: 1000;
    font-size: 2.5em;
  }
  .pricing-description ul {
    padding-left: 0;
  }
  .pricing-description li {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  .pricing-description li:last-child {
    border-bottom: none;
  }
  .pricing-button {
    text-align: center;
    margin-top: auto;
  }
`;

// Injecting the styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Pricing;

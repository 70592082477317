import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="policy-content">
        <h2>Privacy Policy</h2>
        <p>
          At PlanOCast, we are committed to protecting your privacy. This
          Privacy Policy explains how we collect, use, disclose, and safeguard
          your information when you visit our website www.planocast.com or use
          our services.
        </p>
        <h3>Information We Collect</h3>
        <p>
          We may collect information about you in a variety of ways. The
          information we may collect on the Platform includes:
        </p>
        <ul>
          <li>
            <strong>Personal Data:</strong> Personally identifiable information,
            such as your name, shipping address, email address, and telephone
            number, that you voluntarily give to us when you register with the
            Platform or when you choose to participate in various activities
            related to the Platform, such as online chat and message boards.
          </li>
          <li>
            <strong>Derivative Data:</strong> Information our servers
            automatically collect when you access the Platform, such as your IP
            address, your browser type, your operating system, your access
            times, and the pages you have viewed directly before and after
            accessing the Platform.
          </li>
          <li>
            <strong>Financial Data:</strong> Financial information, such as data
            related to your payment method (e.g., valid credit card number, card
            brand, expiration date) that we may collect when you purchase,
            order, return, exchange, or request information about our services
            from the Platform.
          </li>
        </ul>
        <h3>Use of Your Information</h3>
        <p>
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the Platform to:
        </p>
        <ul>
          <li>Administer sweepstakes, promotions, and contests.</li>
          <li>Create and manage your account.</li>
          <li>Email you regarding your account or order.</li>
          <li>Enable user-to-user communications.</li>
          <li>
            Fulfill and manage purchases, orders, payments, and other
            transactions related to the Platform.
          </li>
          <li>
            Generate a personal profile about you to make future visits to the
            Platform more personalized.
          </li>
          <li>Increase the efficiency and operation of the Platform.</li>
          <li>
            Monitor and analyze usage and trends to improve your experience with
            the Platform.
          </li>
          <li>Offer new products, services, and/or recommendations to you.</li>
          <li>Perform other business activities as needed.</li>
          <li>Process payments and refunds.</li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li>
            Request feedback and contact you about your use of the Platform.
          </li>
          <li>Respond to product and customer service requests.</li>
        </ul>
        <h3>Disclosure of Your Information</h3>
        <p>
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <ul>
          <li>
            <strong>By Law or to Protect Rights:</strong> If we believe the
            release of information about you is necessary to respond to legal
            process, to investigate or remedy potential violations of our
            policies, or to protect the rights, property, and safety of others,
            we may share your information as permitted or required by any
            applicable law, rule, or regulation.
          </li>
          <li>
            <strong>Business Transfers:</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
          <li>
            <strong>Third-Party Service Providers:</strong> We may share your
            information with third parties that perform services for us or on
            our behalf, including payment processing, data analysis, email
            delivery, hosting services, customer service, and marketing
            assistance.
          </li>
          <li>
            <strong>Marketing Communications:</strong> With your consent, or
            with an opportunity for you to withdraw consent, we may share your
            information with third parties for marketing purposes, as permitted
            by law.
          </li>
          <li>
            <strong>Interactions with Other Users:</strong> If you interact with
            other users of the Platform, those users may see your name, profile
            photo, and descriptions of your activity, including sending
            invitations to other users, chatting with other users, liking posts,
            following blogs.
          </li>
          <li>
            <strong>Online Postings:</strong> When you post comments,
            contributions, or other content to the Platform, your posts may be
            viewed by all users and may be publicly distributed outside the
            Platform in perpetuity.
          </li>
          <li>
            <strong>Third-Party Advertisers:</strong> We may use third-party
            advertising companies to serve ads when you visit the Platform.
            These companies may use information about your visits to the
            Platform and other websites that are contained in web cookies to
            provide advertisements about goods and services of interest to you.
          </li>
        </ul>
        <h3>Security of Your Information</h3>
        <p>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>
        <h3>Policy for Children</h3>
        <p>
          We do not knowingly solicit information from or market to children
          under the age of 13. If we learn that we have collected information
          from a child under age 13 without verification of parental consent, we
          will delete that information as quickly as possible. If you believe we
          might have any information from or about a child under 13, please
          contact us.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </p>
        <ul>
          <li>
            <strong>Address:</strong>Sector 21,
            Gurugram, Delhi 122016
          </li>
          <li>
            <strong>Contact:</strong> Ashish Kumar Panda, +91 6370516483
          </li>
          <li>
            <strong>Email:</strong> info@planocast.com
          </li>
        </ul>
      </div>
    </div>
  );
};

// CSS styling for the privacy policy component
const styles = `

  .policy-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #0073e6;
  }

  .policy-content h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
  }

  .policy-content p {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .policy-content ul {
    list-style-type: disc;
    margin: 15px 0;
    padding-left: 20px;
  }

  .policy-content ul li {
    margin-bottom: 10px;
  }

  .policy-content ul li strong {
    font-weight: bold;
  }

  .policy-content a {
    color: #0073e6;
    text-decoration: none;
  }

  .policy-content a:hover {
    text-decoration: underline;
  }
`;

// Injecting the styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default PrivacyPolicy;

import { useState } from "react";
import "./ContactUs.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ContactUs = () => {
  const [formValues, setFormValues] = useState({
    city: "",
    company: "",
    contactNumber: "",
    email: "",
    message: "",
    name: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    fetch("https://planocast.azurewebsites.net/osem/v1/addNewLead", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.statusCode === 200) {
          toast("Thank you for contacting us. We will revert back soon.");
          setFormValues({
            city: "",
            company: "",
            contactNumber: "",
            email: "",
            message: "",
            name: "",
          });
        } else {
          toast("Submission Failed. Try again after some time.");
        }
        setIsLoading(false);
      });
  };

  return (
    <section className="contact" id="contact">
      <ToastContainer />
      <div className="container">
        <div className="main">
          <div className="row">
            <div className="col-lg-5 right">
              <h4>Give Us A Try</h4>
              <button
                className="btn btn-primary"
                onClick={() => {
                  window.open(`https://calendly.com/planocast/30min`, "_blank");
                }}
              >
                Book A FREE Demo
              </button>
              <br />
              <br />
              <h6>Or</h6>
              <br />
              <h4>Contact Us At</h4>
              <div className="info d-flex align-items-center">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <span>Gurugram</span>
              </div>
              <div className="info d-flex align-items-center">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                <span>info@planocast.com</span>
              </div>
              <div className="info d-flex align-items-center">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <span>+91 6370516483</span>
              </div>
            </div>
            <div className="col-lg-7 left">
              <h3>Still Got Some Doubts?</h3>
              <form>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      value={formValues?.name}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={formValues?.email}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Contact Number"
                      name="contactNumber"
                      value={formValues?.contactNumber}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company"
                      name="company"
                      value={formValues?.company}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      name="city"
                      value={formValues?.city}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="5"
                    id="comment"
                    placeholder="Message"
                    name="message"
                    value={formValues?.message}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
                <button
                  className="btn btn-light btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Sending..." : "Send Now!"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

import React from "react";

export const CancellationRefundPolicy = () => {
  return (
    <div className="container">
      <div className="policy-content">
        <h2>Cancellation and Refund Policy</h2>
        <p>
          This cancellation and refund policy outlines how you can cancel or
          seek a refund for a service that you have purchased through PlanOCast.
          Under this policy:
        </p>
        <ul>
          <li>
            Cancellations will only be considered if the request is made within
            3 days of purchasing the service. However, cancellation requests may
            not be entertained if the services have already been initiated or
            completed. In such cases, you may contact our customer service for
            further assistance.
          </li>
          <li>
            PlanOCast does not accept cancellation requests for services that
            have already been rendered or are in progress. However, a refund can
            be made if the user establishes that the quality of the service
            provided is not satisfactory.
          </li>
          <li>
            In case of any issues with the service provided, please report to
            our customer service team. The request will be entertained once the
            issue has been verified. This should be reported within 3 days of
            availing the service.
          </li>
          <li>
            In case you feel that the service received is not as described or as
            per your expectations, you must bring it to the notice of our
            customer service within 3 days of receiving the service. The
            customer service team, after looking into your complaint, will take
            an appropriate decision.
          </li>
          <li>
            In case of any refunds approved by PlanOCast, it will take 7-14 days
            for the refund to be processed to you.
          </li>
        </ul>
        <p>
          For any queries or assistance, please contact our customer service
          team at:
        </p>
        <ul>
          <li>
            <strong>Address:</strong>Sector 21,
            Gurugram, Delhi 122016
          </li>
          <li>
            <strong>Contact:</strong> Ashish Kumar Panda, +91 6370516483
          </li>
          <li>
            <strong>Email:</strong> info@planocast.com
          </li>
        </ul>
      </div>
    </div>
  );
};

// CSS styling for the policy component
const styles = `

  .policy-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #0073e6;
  }

  .policy-content p {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .policy-content ul {
    list-style-type: disc;
    margin: 15px 0;
    padding-left: 20px;
  }

  .policy-content ul li {
    margin-bottom: 10px;
  }

  .policy-content ul li strong {
    font-weight: bold;
  }

  .policy-content a {
    color: #0073e6;
    text-decoration: none;
  }

  .policy-content a:hover {
    text-decoration: underline;
  }
`;

// Injecting the styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default CancellationRefundPolicy;

import { FeatureCard } from "./FeatureCard";

export const Features = () => {
  const features = [
    {
      id: "1",
      featureTitle: "Engage Leads Effortlessly",
      featureDescription:
        "Transform leads into active participants with PlanOCast. Our automated WhatsApp messages provide insights into your expertise and past events, making lead engagement seamless.",
      featureImgLink: "leadEngagement.webp",
    },
    {
      id: "2",
      featureTitle: "Seamless Vendor Collaboration",
      featureDescription:
        "Onboard, communicate, and collaborate seamlessly with PlanOCast. Simplify event planning and ensure flawless execution for successful collaborations.",
      featureImgLink: "vendorCollaboration.webp",
    },
    {
      id: "10",
      featureTitle: "PDF Generation with Upselling",
      featureDescription:
        "Generate instant PDFs with detailed plans using PlanOCast. After discussions with customers, upsell additional services seamlessly, maximizing your event planning opportunities.",
      featureImgLink: "customerUpselling.webp",
    },
    {
      id: "3",
      featureTitle: "Effortless Event Planning",
      featureDescription:
        "Streamline the event planning process with PlanOCast's pre-designed templates. Craft perfect events with ease, manage tasks efficiently, and stay organized with live reminders and a centralized dashboard.",
      featureImgLink: "effortlessPlanning.webp",
    },
    {
      id: "5",
      featureTitle: "Digital Invitations and Guest Management",
      featureDescription:
        "Modernize invites with digital sophistication using PlanOCast. Enhance RSVP experiences and streamline guest management for a more organized event.",
      featureImgLink: "guestManagement.webp",
    },
    {
      id: "6",
      featureTitle: "Customer Event Feeds",
      featureDescription:
        "Create personalized event feeds capturing memorable moments with PlanOCast. Share success with a dedicated feed, building community engagement.",
      featureImgLink: "socialFeed.webp",
    },
    {
      id: "7",
      featureTitle: "Post-Event Feedback",
      featureDescription:
        "Gather valuable post-event feedback for continuous improvement with PlanOCast. Ensure each event surpasses expectations, leading to greater success.",
      featureImgLink: "customerFeedback.webp",
    },
    {
      id: "8",
      featureTitle: "Email Marketing Support",
      featureDescription:
        "Build lasting relationships with post-event email marketing support from PlanOCast. Nurture future successes effortlessly, strengthening your client connections.",
      featureImgLink: "emailMarketing.webp",
    },
    {
      id: "9",
      featureTitle: "Analytics and Budget Management",
      featureDescription:
        "Gain insights into company performance and budget management with PlanOCast's analytics. Empower decision-making with our dashboard, optimizing your event planning strategies.",
      featureImgLink: "budgetManagement.webp",
    },
  ];

  return (
    <div className="features-section" id="features">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 text-left">
            <div className="container">
              <div className="row">
                <small
                  style={{
                    fontWeight: "700",
                    color: "var(--primary-color)",
                    marginBottom: "1rem",
                  }}
                >
                  EVENT PLANNING FEATURES
                </small>
              </div>
              <div className="row">
                <p className="h4" style={{ marginBottom: "1rem" }}>
                  PlanOCast: Elevating Every Step of Your Event Planning Process
                </p>
                <small
                  className="text-secondary"
                  style={{ lineHeight: "1.5rem", marginBottom: "1rem" }}
                >
                  Engage leads, collaborate seamlessly, and streamline planning
                  with PlanOCast. Stay organized with real-time updates,
                  modernize invitations, and gather valuable feedback. Join our
                  community and be part of the event planning revolution with
                  PlanOCast!
                </small>
                {/* <button className="btn">Know More</button> */}
              </div>
            </div>
          </div>
          {features?.map((feature) => (
            <div className="col-md-3 col-xs-5 col-sm-5" key={feature.id}>
              <FeatureCard {...feature} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import headerWebImage from "../../assets/images/v3/thumb-v3-01.png";
import headerMobileImage from "../../assets/images/v3/card-v3-1.png";
import checkImage from "../../assets/images/v3/check.png";

const Header = () => {
  return (
    <div className="planocast-hero-section">
      <div className="container">
        <div className="planocast-hero-content text-center">
          <h1>India's First All-in-One Event Planning App</h1>
          <p style={{ maxWidth: "60%", margin: "1rem auto" }}>
            Planocast is an affordable, scalable, and easy-to-use event planning
            tool. Our platform provides everything you need to manage events
            effortlessly and efficiently.
          </p>
          <div className="planocast-extra-mt">
            <a
              className="planocast-default-btn planocast-header-btn pill"
              style={{ marginRight: "1rem" }}
              href="https://calendly.com/planocast/30min"
              target="_blank"
            >
              <span>Book A Consultation</span>
            </a>
            <a
              className="planocast-default-btn planocast-header-btn pill"
              href="https://vccground.planocast.com"
            >
              <span>Get Started</span>
            </a>
            <div className="planocast-icon-list">
              <ul>
                <li>
                  <img src={checkImage} alt="Check" /> No credit card required
                </li>
                <li>
                  <img src={checkImage} alt="Check" /> 7-day free trial
                </li>
              </ul>
            </div>
          </div>
        </div>
        <br />
        <div className="planocast-border-bottom">
          <div className="planocast-hero-thumb3">
            <img
              src={headerWebImage}
              className="img-fluid"
              alt="PlanOCast Header"
            />
            <div className="planocast-hero-thumb-card1">
              <img src={headerMobileImage} width="200" alt="Card" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

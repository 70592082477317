import React from "react";
import "./FeatureCard.css";
export const FeatureCard = (props) => {
  return (
    <div className="image-flip" ontouchstart="this.classList.toggle('hover');">
      <div className="mainflip">
        <div className="frontside">
          <div className="card">
            <div className="card-body text-center">
              <p className="card-image">
                <img
                  className="img-fluid"
                  src={`images/${props?.featureImgLink}`}
                  alt="card"
                />
              </p>
              <p style={{ fontSize: "0.8rem" }}>{props?.featureTitle}</p>
            </div>
          </div>
        </div>
        <div className="backside">
          <div className="card">
            <div className="card-body text-center mt-4">
              <p className="card-text" style={{ lineHeight: "1rem" }}>
                {props?.featureDescription}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";

export const FAQs = () => {
  const [faqs, setFaqs] = useState([
    {
      id: "1",
      question: "What is PlanOCast and how does it benefit event planners?",
      answer:
        "PlanOCast is a comprehensive event planning platform that benefits planners by streamlining the entire event management process. From engaging leads to post-event activities, it offers a range of features to enhance efficiency and success.",
    },
    {
      id: "2",
      question: "How can PlanOCast transform my event planning experience?",
      answer:
        "PlanOCast transforms your event planning experience by automating lead engagement, simplifying vendor collaboration, providing real-time updates, and offering features like digital invitations, event feeds, and post-event feedback. It's designed to make planning stress-free and successful.",
    },
    {
      id: "3",
      question: "Is PlanOCast suitable for both small and large-scale events?",
      answer:
        "Absolutely! PlanOCast is designed to cater to events of all scales. Whether you're planning a small gathering or a large-scale event, the platform's features are customizable to meet the unique needs of your event.",
    },
    {
      id: "4",
      question: "How does PlanOCast handle data security and privacy?",
      answer:
        "We take data security and privacy seriously. PlanOCast employs robust security measures to protect your data, ensuring that your event planning information is safe and secure.",
    },
    {
      id: "5",
      question: "Can I use PlanOCast for multiple events simultaneously?",
      answer:
        "Yes, you can use PlanOCast for multiple events simultaneously. The platform's user-friendly dashboard allows planners to manage and track the progress of various events seamlessly.",
    },
    {
      id: "6",
      question: "How can I get started with PlanOCast?",
      answer:
        "Getting started with PlanOCast is easy. Simply Book a 10-min Demo with Us. After that, we will onboard you to the application.",
    },
    {
      id: "8",
      question: "How does PlanOCast provide post-event support?",
      answer:
        "PlanOCast provides post-event support through features like email marketing and continuous improvement based on valuable feedback. Nurture long-term relationships with clients and ensure each event is better than the last.",
    },

    {
      id: "10",
      question: "How does PlanOCast stay updated with industry trends?",
      answer:
        "PlanOCast is committed to staying updated with industry trends. Our platform evolves with the changing landscape of event planning, ensuring that you have access to the latest tools and features for success.",
    },
  ]);

  return (
    <div className="container" id="faqs">
      <div className="row d-flex justify-content-center faqs">
        <div className="col-md-10">
          <div className="container">
            <div className="row">
              <p style={{ fontWeight: "700", color: "var(--primary-color)" }}>
                FREQUENTLY ASKED QUESTIONS ABOUT PLANOCAST EVENT PLANNING
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="accordion accordion-flush">
            {faqs?.slice(0, 4).map((faq) => (
              <div className="accordion-item" key={faq.id}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${faq.id}`}
                    aria-expanded="false"
                    aria-controls={`#flush-collapse${faq.id}`}
                    style={{ lineHeight: "1rem" }}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${faq.id}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <small>{faq.answer}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-5">
          <div className="accordion accordion-flush">
            {faqs?.slice(4).map((faq) => (
              <div className="accordion-item" key={faq.id}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${faq.id}`}
                    aria-expanded="false"
                    aria-controls={`#flush-collapse${faq.id}`}
                    style={{ lineHeight: "1rem" }}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${faq.id}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <small>{faq.answer}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
